//分割したファイルの呼び出し
// document.addEventListener("DOMContentLoaded", function () {
//     const incElements = document.querySelectorAll('[class*="inc_"]');
//     incElements.forEach(incElement => {
//         const className = incElement.className;
//         const fileNamePart = className.split('inc_')[1];
//         const fileName = `inc_${fileNamePart}.html`;
//         const filePath = `common/include/${fileName}`;

//         fetch(filePath)
//             .then(response => response.text())
//             .then(data => {
//                 incElement.innerHTML = data;
//                 const hamMenu = document.querySelector('.ham-menu');
//                 const ham = document.querySelector('.ham');
//                 hamMenu.addEventListener('click', () => {
//                     ham.classList.toggle('--active');
//                 });
//             })
//             .catch(error => console.error('Error:', error));
//     });
// });


//=========どの階層でも読み込まれるように調整(ローカル・テスト)=========//
// document.addEventListener("DOMContentLoaded", function () {
//     const incElements = document.querySelectorAll('[class*="inc_"]');

//     // 現在のページのパスを取得し、ディレクトリの深さを計算
//     const currentPath = window.location.pathname;
//     const depth = currentPath.split('/').filter(part => part.length > 0).length;

//     // test/ からの相対パスを計算
//     const relativePath = '../'.repeat(depth - 1) + 'common/include/';

//     incElements.forEach(incElement => {
//         const className = incElement.className;
//         const fileNamePart = className.split('inc_')[1];
//         const fileName = `inc_${fileNamePart}.html`;
//         const filePath = `${relativePath}${fileName}`;

//         fetch(filePath)
//             .then(response => response.text())
//             .then(data => {
//                 incElement.innerHTML = data;
//                 const hamMenu = document.querySelector('.ham-menu');
//                 const ham = document.querySelector('.ham');
//                 if (hamMenu && ham) {
//                     hamMenu.addEventListener('click', () => {
//                         ham.classList.toggle('--active');
//                     });
//                 }
//             })
//             .catch(error => console.error('Error:', error));
//     });
// });


//分割したファイルの呼び出し（本番上げるようコード）
document.addEventListener("DOMContentLoaded", function () {
    const incElements = document.querySelectorAll('[class*="inc_"]');

    // URL直下のcommon/include/のパス
    const basePath = '/common/include/';

    incElements.forEach(incElement => {
        const className = incElement.className;
        const fileNamePart = className.split('inc_')[1];
        const fileName = `inc_${fileNamePart}.html`;
        const filePath = `${basePath}${fileName}`;

        fetch(filePath)
            .then(response => response.text())
            .then(data => {
                incElement.innerHTML = data;
                const hamMenu = document.querySelector('.ham-menu');
                const ham = document.querySelector('.ham');
                if (hamMenu && ham) {
                    hamMenu.addEventListener('click', () => {
                        ham.classList.toggle('--active');
                    });
                }
            })
            .catch(error => console.error('Error:', error));
    });
});


//=========ローディング=========//
// const loading = document.querySelector('.loading');
// const mvfadein = document.querySelector('.mv-fadeIn');
// const mvperson = document.querySelector('.mv-person');
// const mvtext = document.querySelector('.mv-text');
// window.addEventListener('load', () => {
//     loading.classList.add('--active');
//     setTimeout(() => {
//         loading.classList.add('--hidden');
//     }, 3000);
//     setTimeout(() => {
//         mvfadein.classList.add('--active');
//         mvperson.classList.add('--active');
//         mvtext.classList.add('--active');
//     }, 3500); //4000
// }, false);

const loading = document.querySelector('.loading');
const mvfadein = document.querySelector('.mv-fadeIn');
const mvperson = document.querySelector('.mv-person');
const mvtext = document.querySelector('.mv-text');

// 初回訪問かどうかを確認
const isFirstVisit = sessionStorage.getItem('firstVisit');

// window.addEventListener('load', () => {
// document.addEventListener("DOMContentLoaded", function() {
//     if (isFirstVisit !== 'true') {
//         loading.classList.add('--active');
//         setTimeout(() => {
//             loading.classList.add('--hidden');
//         }, 3000);
//         // console.log('初回アクセスです');

//         setTimeout(() => {
//             mvfadein.classList.add('--active');
//             mvperson.classList.add('--active');
//             mvtext.classList.add('--active');
//         }, 3500); // 4000

//         // 初回訪問フラグをセット
//         sessionStorage.setItem('firstVisit', 'true');

//     } else {
//         // loading.classList.add('--none');
//         mvfadein.classList.add('--active');
//         mvperson.classList.add('--active');
//         mvtext.classList.add('--active');
//         // console.log('2回目以降のアクセスです');
//     }

// }, false);

if (isFirstVisit !== 'true') {
    // DOMContentLoaded での処理
    document.addEventListener("DOMContentLoaded", function() {
        loading.classList.add('--active');
        setTimeout(() => {
            loading.classList.add('--hidden');
        }, 3000);

        setTimeout(() => {
            mvfadein.classList.add('--active');
            mvperson.classList.add('--active');
            mvtext.classList.add('--active');
        }, 3500);

        // 初回訪問フラグをセット
        sessionStorage.setItem('firstVisit', 'true');
    }, false);
} else {
    // 2回目以降の処理 (load イベントでの処理)
    window.addEventListener('load', () => {
        mvfadein.classList.add('--active');
        mvperson.classList.add('--active');
        mvtext.classList.add('--active');
    });
}



//=========追従スクロールでハイライト=========//
document.addEventListener("DOMContentLoaded", function() {
    var links = document.querySelectorAll(".aside-nav li a");
    var offset = 20;  // オフセットを少し低く設定

    function highlightLink() {
        var scrollPosition = window.scrollY + (window.innerHeight / 3); // 画面の3分の1

        // 全てのリンクからハイライトを削除
        links.forEach(function(link) {
            link.classList.remove("highlight");
        });

        // スクロール位置に基づいてハイライトを再度付ける
        links.forEach(function(link) {
            var sectionId = link.getAttribute("href").replace(/^#/, "");
            var section = document.getElementById(sectionId);

            if (!section) return;

            var sectionTop = section.offsetTop - offset;
            var sectionBottom = sectionTop + section.clientHeight;

            if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                link.classList.add("highlight");
            }
        });
    }

    // function onScroll() {
    //     highlightLink();
    // }
    function onScroll() {
        if (window.innerWidth >= 768) { // スクリーン幅が768px以上の場合
            highlightLink();
        }
    }

    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onScroll);

    // 初回実行
    // highlightLink();
    if (window.innerWidth >= 768) { // スクリーン幅が768px以上の場合
        highlightLink();
    }
});


document.addEventListener('DOMContentLoaded', () => {
    const aboutSection = document.getElementById('about');
    const mvImg = document.querySelector('.mv-img');

    const adjustOpacity = () => {
        const aboutTop = aboutSection.getBoundingClientRect().top + window.scrollY;
        const scrollY = window.scrollY;

        const offset = aboutTop;

        if (scrollY >= offset) {
            mvImg.style.filter = 'opacity(0)';
        } else {
            const opacity = 1 - scrollY / offset;
            console.log(Math.max(opacity, 0));
            mvImg.style.filter = `opacity(${Math.max(opacity, 0)})`;
        }
    };

    window.addEventListener('scroll', adjustOpacity);
    adjustOpacity();
});


//=========アコーディオン=========//
const accordion = document.querySelectorAll(".js-accordion"); 
function acToggle() {
    const content = this.nextElementSibling; 
    content.classList.toggle("is-open");
    const accordion = this; 
    accordion.classList.toggle('is-open'); 
}

for (let i = 0; i < accordion.length; i++) { // for文でjs-accordionメニューをループ処理
    accordion[i].addEventListener("click", acToggle);
}


//=========ページトップ=========//
function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}


//=========Safariかどうかを判定しクラスを追加=========//
// document.addEventListener('DOMContentLoaded', function() {
//     function isSafari() {
//         return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
//     }

//     if (isSafari()) {
//         const mvPerson = document.querySelector('main.top .mv .sec-block2');
//         if (mvPerson) {
//             mvPerson.classList.add('safari');
//         }
//     }
// });




