// Intersection Observer を使って要素のビューポートへの入出を監視し、
// 特定の処理（Processing）関数を実行する関数
const animationObserver = (elements, timing, processing) => {
  // Intersection Observerのオプション設定
  const options = {
    root: null, // ビューポートを基準とする
    rootMargin: timing, // ルートのマージンを設定（ビューポートの外側からの監視を可能にする）
    threshold: 0, // どのくらい交差したら関数（callback）を呼び出すか
  };

  // Intersection Observerのコールバック関数
  const callback = (entries) => {
    entries.forEach((entry) => {
      processing(entry, observer);
    });
  };

  // Intersection Observerのインスタンス作成
  const observer = new IntersectionObserver(callback, options);

  // NodeListを配列に変換
  const targetArray = Array.from(elements);

  // 各要素を監視対象に追加
  targetArray.forEach((target) => {
    observer.observe(target);
  });
};

// 要素がビューポートに入った時に「visible」クラスを追加し、監視を停止する処理
const handleScrollVisible = (entry, observer) => {
  if (entry.isIntersecting) {
    // ビューポートに入った時の処理
    entry.target.classList.add('visible');
    observer.unobserve(entry.target); // 監視を解除
  }
};

// 動画要素がビューポートに入っていたら再生、出ていたら停止する処理
const handleScrollPlayMovie = (entry, observer) => {
  if (entry.isIntersecting) {
    // ビューポートに入っている時の処理
    entry.target.play();
  } else {
    // ビューポートから出ている時の処理
    entry.target.pause();
  }
};

// 対象要素を全て取得
const targets = document.querySelectorAll('[data-trigger]');
if (targets) {
  // 対象要素がある時、スクロールに応じて表示するアニメーションを設定
  animationObserver(targets, '0px 0px -32px 0px', handleScrollVisible);
}

// // 動画要素を全て取得
// const targets2 = document.querySelectorAll('[data-trigger-video]');
// if (targets) {
//   // 対象要素がある時、ビューポート内に入ると再生、出ると停止するよう設定
//   animationObserver(targets2, '0px', handleScrollPlayMovie);
// }


// 画像のアニメーション設定（ベタが流れてから画像表示）
// js-img-show要素を全て取得し、imagesという変数に格納
const images = document.querySelectorAll('.js-img-show');
const animationClassName = 'anim__img';

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add(animationClassName);
      observer.unobserve(entry.target);
    }
  });
});
images.forEach((image) => {
  observer.observe(image);
});
